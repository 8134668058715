<template>
	<div>
		<create-user />
	</div>
</template>

<script>
	import createUser from '../../components/adminUsers/createUser.vue';
	export default {
		components: { createUser },
		name: 'NewUser',
		title: 'Info Feed | Infoguia',
		data() {
			return {};
		},
		beforeCreate: function() {},
		created() {},
		methods: {},
		mounted() {},
	};
</script>
